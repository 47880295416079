import { types } from "../_actions";

const sortReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GAME_SORT:
            return {
                ...state,
                game: {
                    sortKey: action.payload,
                    order: state?.game?.order && state.game.order === 'asc' ? 'desc' : 'asc'
                }
            };

        default:
            return state;
    }

}

export default sortReducer;
