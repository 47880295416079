import React from 'react';
import { reduxForm, Field } from 'redux-form';
import i18n from 'i18next';
import { Input, required, email, minLength6, DropDown, Header, ActionPanel } from '../_shared';

class UserForm extends React.Component {

    onSubmit = formValues => {
        this.props.onSubmit(formValues);
    }

    renderPasswordInfo() {
        return this.props.editMode
            ? (<div className="ui visible message">
                <i className="info icon"></i>
                {i18n.t('EmptyForNoChange')}
            </div>)
            : null;
    }

    render() {
        return (
            <form
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                className="ui form error"
            >
                <Header title={this.props.title} />

                <Field
                    component={Input}
                    name="firstName"
                    label="FirstName"
                    validate={[required]}
                />

                <Field
                    component={Input}
                    name="lastName"
                    label="LastName"
                    validate={[required]}
                />

                <Field
                    component={Input}
                    name="email"
                    label="Email"
                    inputType="email"
                    validate={[required, email]}
                />

                <Field
                    component={DropDown}
                    name="role"
                    label="Role"
                    data={[{ id: 1, name: "SuperAdmin" }, { id: 2, name: "Professor" }, { id: 4, name: "Default" }]}
                    validate={[required]}
                />

                <Field
                    component={DropDown}
                    name="state"
                    label="State"
                    data={[{ id: 0, name: "Active" }, { id: 1, name: "InActive" }, { id: 2, name: "NotVerified" }]}
                    validate={[required]}
                />

                <Field
                    component={Input}
                    name="password"
                    label="Password"
                    inputType="password"
                    validate={this.props.editMode ? [] : [required, minLength6]}
                />

                {this.renderPasswordInfo()}

                <ActionPanel to="/users"
                    onDeleteClick={this.props.onDelete}
                    deleteMode={this.props.deleteMode}
                />

            </form>
        );
    }
}

const wrappedForm = reduxForm(
    {
        form: 'user_form'
    }
)(UserForm);

export { wrappedForm as UserForm };
