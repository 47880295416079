import React from 'react';
import i18n from 'i18next';

export const Input = ({ input, label, meta, inputType = "text" }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

    return (
        <div className={className}>
            <div className="ui labeled input">
                <div className="ui label blue secondary input_label">{i18n.t(label)}</div>
                <input {...input} autoComplete="off" type={inputType} />
            </div>
        </div>
    )
};
