import { http } from './http.service';
import { EndPoints } from '../_configs';

class AuthorizationService {

  static _KEY = 'user';

  static isAuthorized() {
    if (this._localGet(this._KEY)) {
      return true;
    }

    return false;
  }

  static getRole() {
    var user = this._localGet(this._KEY);
    if (user) {
      return user.role;
    }
    return null;
  }

  static login(email, password) {

    return http.post(EndPoints.LOGIN, { email, password })
      .then(user => {
        this._localSave(this._KEY, user);
        return user;
      });
  }

  static verify(email, code) {

    return http.post(EndPoints.VERITY, { email, code });
  }

  static getCurrentUser() {
    const user = this._localGet(this._KEY);
    return user;
  }

  static getCurrentUserToken() {
    const user = this._localGet(this._KEY);
    return !user ? null : user.accessToken;
  }

  static logout() {
    this._localRemove(this._KEY);
  }

  static _localSave(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static _localRemove(key) {
    localStorage.removeItem(key);
  }

  static _localGet(key) {
    return JSON.parse(localStorage.getItem(key));
  }
}

export { AuthorizationService };
