import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import auth from "./auth.reducer";
import users from "./user.reducer";
import results from "./result.reducer";
import receptors from "./receptor.reducer";
import dockingConfigs from "./dockingConfig.reducer";
import games from "./game.reducer";
import search from "./search.reducer";
import sort from "./sort.reducer";
import general from "./general.reducer";

const rootReducer = combineReducers({
  form: formReducer,
  auth,
  users,
  results,
  receptors,
  dockingConfigs,
  games,
  search,
  sort,
  general,
});

export default rootReducer;
