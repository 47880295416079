import { ApiCombination, ApiFile, ApiFileType, ApiProject, ApiProjectResult } from "models";
import useSWR from "swr";
import { EndPoints, getEndpoint } from "_configs";
import { http } from "_services/http.service";

export const useFiles = (fileType: ApiFileType, tags: string[] = []) => useSWR<ApiFile[]>(getEndpoint(fileType)(tags), http.get);

export const useTags = () => useSWR<string[]>(EndPoints.TAG_GET_ALL, http.get);

export const useProjectFiles = (projectId?: string, tags?: Record<ApiFileType, string[]>) => useSWR<Record<ApiFileType, ApiFile[]>>(projectId && tags ? EndPoints.PROJECT_GET_FILES(projectId, tags) : null, http.get);

export const useProjects = () => useSWR<ApiProject[]>(EndPoints.PROJECT_GET_ALL, http.get);
export const useProject = (projectId?: string) => useSWR<ApiProject>(projectId ? EndPoints.PROJECT_GET(projectId) : null, http.get);

export const useProjectResults = () => useSWR<ApiProjectResult[]>(EndPoints.PROJECT_GET_RESULTS, http.get);
export const useProjectResultCombinations = (projectId?: string) => useSWR<ApiCombination[]>(projectId ? EndPoints.PROJECT_GET_RESULT_COMBINATIONS(projectId) : null, http.get);
