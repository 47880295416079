import { types } from './types';
import { AuthorizationService } from '../_services';
import { toAction, history } from '../_helpers';

export const authActions = {
  login,
  logout,
  verify
}

function login(email, password) {
  return dispatch => {
    AuthorizationService.login(email, password)
      .then(
        response => {
          dispatch(toAction(types.LOGIN_SUCCESS, response));
          history.push('/');
        },
        err => {
          dispatch(toAction(types.LOGIN_FAILURE, err));
        });
  }
}

function logout() {
  return dispatch => {
    AuthorizationService.logout();
    dispatch(toAction(types.LOGOUT));
    history.push('/login');
  }
}

function verify(email, code) {
  return dispatch => {
    AuthorizationService.verify(email, code)
      .then(
        response => {
          dispatch(toAction(types.VERIFY, response.isVerified));
          history.push(`/result/${response.isVerified}`);
        },
        err => {
          console.log('Verification Response #Error', err);
        }
      );
  }
}
