import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { userActions } from '../../_actions';

class HomePage extends React.Component {

    render() {

        return (
            <div className="ui placeholder segment primary">
                <div className="ui icon huge header">
                    {i18n.t('Hello')}
                </div>
            </div>
        );
    }
}

const actionCreator = {
    logout: userActions.logout
};

const connectedPage = connect(null, actionCreator)(HomePage);

export { connectedPage as HomePage };
