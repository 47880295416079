export const english = {
  "Hello": "Hello",
  "Back": "Back",
  "Submit": "Submit",
  "CopyToClipboard": "Copy To Clipboard",
  "SelectAnItem": "Select an item",
  "Pause": "Pause",
  "Next": "Next",
  "Search": "Search...",
  "PlayerGroup": "Player Group",
  "University": "University",
  "RoundNumber": "Round number",
  "Registered": "Registered",
  "FirstPlayerThrough": "First player through",
  "NewGame": "New game",
  "ChangePassDescription": "Keep empty for no changes",
  "ChangePassword": "Change Password",
  "Done": "Done",
  "GoToGame": "Go to game page!",
  "Fail": "Fail",
  "Users": "User",
  "Games": "Games",
  "Universities": "Universities",
  "Logout": "Logout",
  "Name": "Name",
  "Code": "Code",
  "Active": "Active",
  "NewUniversity": "New university",
  "EmptyForNoChange": "Keep empty for no changes",
  "FirstName": "First name",
  "LastName": "Last name",
  "Email": "Email",
  "NewUser": "New user",
  "EditUser": "EDIT USER",
  "CreateUser": "CREATE NEW USER",
  "AddGame": "START NEW GAME",
  "AddUniversity": "ADD NEW UNIVERSITY",
  "EditUniversity": "EDIT UNIVERSITY",
  "DangerZone": "Caution",
  "DeleteWarning": "Once you delete a user, there is no going back. Please be certain.",
  "Delete": "Delete",
  "SelectLigand": "Select Ligand"
}
