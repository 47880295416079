import React from 'react';
import i18n from 'i18next';

function onCopy(event) {
    var copyText = event.target.parentElement.parentElement.querySelector("input");
    copyText.disabled = false;
    copyText.select();
    document.execCommand("copy");
    copyText.disabled = true;
};

export const ClipboardInput = ({ input, label, inputType = "text" , text}) => {
    return (
        <div className="field">
            <div className="ui labeled action input">
                <div className="ui label blue secondary input_label">{i18n.t(label)}</div>
                <input {...input} autoComplete="off" type={inputType} disabled value={text}/>
                <span className="ui primary basic right labeled icon button" onClick={onCopy}>
                    <i className="copy icon"></i>
                    {i18n.t('CopyToClipboard')}
                </span>

            </div>
        </div>

    )
};
