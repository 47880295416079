import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { userActions, generalActions } from '../../_actions';

class UserList extends React.Component {

    componentDidMount() {
        this.props.getAllUsers();
    }

    onSearch = (event) => {
        this.props.search(event.target.value)
    }

    renderUserRecord() {

        return this.props.users.map(user => {
            return (
                <tr key={user.id}>
                    <td>
                        {user.firstName}
                    </td>
                    <td>
                        {user.lastName}
                    </td>
                    <td>
                        {user.email}
                    </td>
                    <td>
                        <div className="ui icon buttons mini">
                            <Link className="ui button" to={`/users/edit/${user.id}`}>
                                <i className="edit icon"></i>
                            </Link>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    renderUserTable() {
        return (
            <table className="ui single line table">
                <thead>
                    <tr>
                        <th colSpan="4">
                            <div className="ui icon input">
                                <input
                                    className="prompt"
                                    type="text"
                                    placeholder={i18n.t('Search')}
                                    onChange={this.onSearch}
                                ></input>
                                <i className="search icon"></i>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>{i18n.t('FirstName')}</th>
                        <th>{i18n.t('LastName')}</th>
                        <th>{i18n.t('Email')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderUserRecord()}
                </tbody>
                <tfoot >
                    <tr>
                        <th colSpan="4">
                            <Link to="/users/create" className="ui right floated small primary labeled icon button">
                                {i18n.t('NewUser')}
                                <i className="user icon"></i>
                            </Link>
                        </th>
                    </tr>
                </tfoot>
            </table>
        );
    }

    render() {
        return (
            <div>
                {this.renderUserTable()}
            </div>
        );
    }
}

const actionCreator = {
    getAllUsers: userActions.getAll,
    search: userActions.search,
    loading: generalActions.loading,
}

const mapStateToProps = (state) => {
    var users = Object.values(state.users);
    // const key = state.search.account;
    // if (key) {
    //     users = users.filter(i =>
    //         (`${i.firstName} ${i.lastName} ${i.email}`).toLowerCase().includes(key)
    //     );
    // }

    return { users };
}

const connectedUserMenu = connect(mapStateToProps, actionCreator)(UserList);

export { connectedUserMenu as UserList };
