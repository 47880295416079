import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import React from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import { AuthorizationService } from '../../_services';
import { history } from '../../_helpers';

import { Menu } from '../Menu';
import { LoginPage } from '../LoginPage';
import { HomePage } from '../HomePage';
import { NotFoundPage } from '../NotFoundPage';
import { UserCreate, UserList, UserEdit } from '../User';
import { EmailVerification, ResetPassword, Result } from '../General';
import { FileCreate, FileList } from 'components/_shared/File';
import { ProjectsList, UpdateProject, UpsertProject } from 'components/ProjectsPage';
import { ResultCreate } from '../ResultsPage';
import { Routes } from '_configs';
import { ResultsList } from 'components/ResultsPage';

type PrivateRouteProps = Route['props'] & { component: React.ComponentType<any> };

const PrivateRoute = ({ component: Component, ...args }: PrivateRouteProps) => (
  <Route
    {...args}
    render={(props) => AuthorizationService.isAuthorized()
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
  />
)

class App extends React.Component {
  props: any;

  renderContent() {
    return (
      <div>
        <div style={{ display: !this.props.loading ? "none" : "block" }}>
          <div className="ui placeholder segment primary">
            <div className="ui active inverted dimmer">
              <div className="ui large text loader">{this.props?.loading?.loaded}</div>
            </div>
          </div>
        </div>
        <div style={{ display: this.props.loading ? "none" : "block" }}>
          <Switch>
            <Route path={Routes.LOGIN_PAGE} component={LoginPage} />
            <Route path={Routes.VERIFY_EMAIL_PAGE} component={EmailVerification} />
            <Route path={Routes.RESET_PASSWORD_PAGE} component={ResetPassword} />

            <PrivateRoute path={Routes.HOME_PAGE} exact component={HomePage} />
            <PrivateRoute path={Routes.USER_LIST_PAGE} exact component={UserList} />
            <PrivateRoute path={Routes.USER_CREATE_PAGE} exact component={UserCreate} />
            <PrivateRoute path={Routes.USER_UPDATE_PAGE} exact component={UserEdit} />

            <PrivateRoute path={Routes.PROJECT_CREATE_PAGE} exact component={UpsertProject} />
            <PrivateRoute path={Routes.PROJECT_UPDATE_PAGE} exact component={UpdateProject} />
            <PrivateRoute path={Routes.PROJECT_LIST_PAGE} exact component={ProjectsList} />

            <PrivateRoute path={Routes.RESULT_CREATE_PAGE} exact component={ResultCreate} />
            <PrivateRoute path={Routes.RESULT_LIST_PAGE} exact component={ResultsList} />

            <PrivateRoute path={Routes.LIGAND_LIST_PAGE} exact component={() => <FileList fileType="Ligand" />} />
            <PrivateRoute path={Routes.LIGAND_CREATE_PAGE} exact component={() => <FileCreate fileType="Ligand" />} />
            <PrivateRoute path={Routes.RECEPTOR_LIST_PAGE} exact component={() => <FileList fileType="Receptor" />} />
            <PrivateRoute path={Routes.RECEPTOR_CREATE_PAGE} exact component={() => <FileCreate fileType="Receptor" />} />
            <PrivateRoute path={Routes.CONFIG_LIST_PAGE} exact component={() => <FileList fileType="Config" />} />
            <PrivateRoute path={Routes.CONFIG_CREATE_PAGE} exact component={() => <FileCreate fileType="Config" />} />

            <Route path={Routes.NOT_FOUND_PAGE} component={NotFoundPage} />
            <Route component={NotFoundPage} />

          </Switch>
        </div>
      </div>);
  }

  render() {
    return (
      <div className="ui container">
        <ToastContainer />
        <Router history={history}>
          <Menu />
          <div className="ui piled blue segment primary">
            {this.renderContent()}
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {

  return {
    loading: state?.general?.loading
  };
}

const connectedComponent = connect(
  mapStateToProps,
  null
)(App);

export { connectedComponent as App };
