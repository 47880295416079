import React from "react";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { Accordion, Button, Icon, Table } from "semantic-ui-react";
import styles from './ResultsList.module.sass';
import { useProjectResultCombinations, useProjectResults, useSelectable } from "hooks";
import { EndPoints, getHttpConfig, Routes } from "_configs";
import { http } from "_services/http.service";

const downloadFile = (pathCreator: (id: string) => string, id: string) => http
  .getResponse(`${getHttpConfig().BASE_URL}${pathCreator(id)}`, {
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${id}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });

export const ResultsList = () => {
  const { data: results } = useProjectResults();
  const { selectedIds, toggleId } = useSelectable(results);

  const downloadRequest = (projectId: string) => downloadFile(EndPoints.PROJECT_GET_RESULTS_FILE, projectId);

  return (
    <Accordion styled style={{ width: '100%' }}>
      <Accordion.Title className={styles.row} style={{ backgroundColor: '#f9fafb', color: 'rgba(0,0,0,.87' }}>
        <div className={styles.cell}>{i18n.t("Name")}</div>
        <div className={styles.cell}>{i18n.t("Progress")}</div>
        <div className={styles.cell}>{i18n.t("Id")}</div>
        <div className={styles.cell} style={{ textAlign: 'right' }}>{i18n.t("Actions")}</div>
      </Accordion.Title>
      {results?.map((result, index) => (<>
        <Accordion.Title active={selectedIds[result.id]} onClick={() => toggleId(result.id)} className={styles.row}>
          <div className={styles.cell}>{result.name}</div>
          <div className={styles.cell}>{result.percentFinished * 100} %</div>
          <div className={styles.cell}>{result.id}</div>
          <div className={styles.cell} style={{ textAlign: 'right' }}>
            <Button icon size="small" primary floated="right" onClick={(e) => { e.stopPropagation(); downloadRequest(result.id); }}>
              <Icon name="download" />
            </Button>
          </div>
        </Accordion.Title>
        <Accordion.Content active={selectedIds[result.id]} className={styles.row}>
          {selectedIds[result.id] && <ResultCombinations projectId={result.id} minResultCount={result.minResultCount} />}
        </Accordion.Content>
      </>))}
      <Accordion.Title style={{ backgroundColor: '#f9fafb', color: 'rgba(0,0,0,.87' }}>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Link to={`/projects/create`} className="ui white" >
                <Button icon size="small" primary floated="right">
                  <Icon name="plus" />
                  {i18n.t(`CreateProject`)}
                </Button>
              </Link>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Accordion.Title>
    </Accordion>
  );
};

interface CombinationProps {
  projectId?: string;
  minResultCount: number;
}

export const ResultCombinations = ({ projectId, minResultCount }: CombinationProps) => {
  const { data: combinations } = useProjectResultCombinations(projectId);

  const downloadRequest = (combinationId: string) => downloadFile(EndPoints.DOCKING_GET_RESULT_FILE, combinationId);

  return (
    <Table className={styles.table}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className={styles.cell}>{i18n.t("Ligand")}</Table.HeaderCell>
          <Table.HeaderCell className={styles.cell}>{i18n.t("Receptor")}</Table.HeaderCell>
          <Table.HeaderCell className={styles.cell}>{i18n.t("Config")}</Table.HeaderCell>
          <Table.HeaderCell className={styles.cell}>{i18n.t("Progress")}</Table.HeaderCell>
          <Table.HeaderCell className={styles.cell}>{i18n.t("Id")}</Table.HeaderCell>

          <Table.HeaderCell className={styles.cell}>{i18n.t("Actions")}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {combinations?.map((combination) => (
          <Table.Row key={combination.id}>
            <Table.Cell>{combination.ligandName}</Table.Cell>
            <Table.Cell>{combination.receptorName}</Table.Cell>
            <Table.Cell>{combination.configurationName}</Table.Cell>
            <Table.Cell>{Math.round((Math.min(minResultCount, combination.resultCount) / minResultCount) * 100)} %</Table.Cell>
            <Table.Cell>{combination.id}</Table.Cell>
            <Table.Cell style={{ textAlign: 'right' }}>
              <Link to={Routes.RESULT_CREATE_PAGE.replace(":combinationId", combination.id)} className="ui white" >
                <Button icon size="small" primary floated="right">
                  <Icon name="plus" />
                </Button>
              </Link>
              <Button icon size="small" primary floated="right" onClick={() => downloadRequest(combination.id)}>
                <Icon name="download" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
