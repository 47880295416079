export const german = {
  "Hello": "Hallo",
  "Back": "Zurück",
  "Submit": "Bestätigen",
  "CopyToClipboard": "In Zwischenablage speichern",
  "SelectAnItem": "Wähle ein Element",
  "Pause": "Pause",
  "Next": "Weiter",
  "Search": "Suchen...",
  "PlayerGroup": "Spieler Gruppe",
  "University": "Universität",
  "RoundNumber": "Spielrunde",
  "Registered": "Registriert",
  "FirstPlayerThrough": "Erster Spieler durch",
  "NewGame": "Neues Spiel",
  "ChangePassDescription": "Leer lassen für keine Änderungen",
  "ChangePassword": "Passwort ändern",
  "Done": "Fertig",
  "GoToGame": "Zum Spiel zurückkehren!",
  "Fail": "Fehler",
  "Users": "Nutzer",
  "Games": "Spiele",
  "Universities": "Universitäten",
  "Logout": "Logout",
  "Name": "Name",
  "Code": "Code",
  "Active": "Aktiv",
  "NewUniversity": "Neue Universität",
  "EmptyForNoChange": "Leer lassen für keine Änderungen",
  "FirstName": "Vorname",
  "LastName": "Nachname",
  "Email": "Email",
  "NewUser": "Neuer Nutzer",
  "EditUser": "NUTZER EDITIEREN",
  "CreateUser": "NEUEN NUTZER ERSTELLEN",
  "AddGame": "NEUES SPIEL STARTEN",
  "AddUniversity": "NEUE UNIVERSITÄT HINZUFÜGEN",
  "EditUniversity": "UNIVERSITÄT EDITIEREN",
  "DangerZone": "Achtung",
  "DeleteWarning": "Achtung sie löschen gerade einen Benutzer, dies kann nicht Rückgängig gemacht werden. Fortfahren?",
  "Delete": "Delete",
  "SelectLigand": "Ligand auswählen"
}
