import { types } from '../_actions';

const searchReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GAME_SEARCH:
            return { ...state, game: action.payload };

        case types.ACCOUNT_SEARCH:
            return { ...state, account: action.payload };

        default:
            return state;
    };
}

export default searchReducer;
