import React from 'react';
import i18n from 'i18next';

export const DropDown = ({ input, label, meta, data }) => {
  const hasError = meta.error && meta.touched;
  const className = `field ${hasError ? 'error' : ''}`;

  return (

    <div className={className}>
      <div className="ui labeled input">
        <div className="ui label blue input_label">{i18n.t(label)}</div>
        <select className="ui search dropdown" {...input}>
          <option value="">{i18n.t('SelectAnItem')}</option>
          {
            data.map(item => {
              return <option key={item.id} value={item.id}>{item.name}</option>;
            })
          }
        </select>
      </div>
    </div>
  );
};
