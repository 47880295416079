import React from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../_helpers';

class NotFoundPage extends React.Component {

    constructor(props) {
        super(props);
        history.push('404');
    }

    render() {
        return (
            <div className="ui grid middle aligned segment blue inverted">
                <div className="sixteen wide column center aligned">
                    <div className="ui inverted statistic">
                        <div className="value">404</div>
                        <div className="label">Error</div>
                    </div>

                    <div className="ui message blue inverted" >
                        <div className="header">Not Found</div>
                        <p>
                            <Link to="/">Go To Home Page</Link>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export { NotFoundPage };
