import React from "react";
import { Accordion, Button, Checkbox, Icon } from "semantic-ui-react";
import { ApiProject } from "models";
import { useToggle } from "react-use";
import styles from './ProjectsList.module.sass';
import { FileListTemplate, FileListTemplateProps } from "components/_shared/File";
import { Link } from "react-router-dom";

interface FileAccordionProps {
  name: string;
  FileList?: React.ReactChild;
}

function FileAccordionItem({ name, FileList }: FileAccordionProps) {
  const [isActive, toggleIsActive] = useToggle(false);
  return (
    <>
      <Accordion.Title active={isActive} onClick={() => toggleIsActive(!isActive)}>
        <Icon name='dropdown' />
        {name}
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        {FileList}
      </Accordion.Content>
    </>
  )
}

export interface TemplateProps {
  isActive?: boolean;
  toggleIsActive?: (nextValue?: any) => void;
  project?: ApiProject;
  Ligand?: React.ReactChild;
  Receptor?: React.ReactChild;
  Config?: React.ReactChild;
  isSelected?: boolean;
  toggleIsSelected?: () => void;
}

export const ProjectCellTemplate = ({
  isActive,
  toggleIsActive,
  project,
  Ligand,
  Receptor,
  Config,
  isSelected,
  toggleIsSelected,
}: TemplateProps) => (
  <>
    {typeof isActive === 'boolean' && toggleIsActive && project && (
      <Accordion.Title active={isActive} onClick={() => toggleIsActive(!isActive)} className={styles.row}>
        <div className={styles.cell}>{project.name}</div>
        <div className={styles.cell}>{project.id}</div>
        <div className={styles.cell}>{project.minResultCount}</div>
        {typeof isSelected === 'boolean' && toggleIsSelected && (
          <div className={styles.editCell} onClick={(e) => e.stopPropagation()}>
            <Link to={`/projects/${project.id}`}>
              <Icon name="edit" />
            </Link>
            <Checkbox checked={isSelected} onClick={() => toggleIsSelected()} />
          </div>
        )}
      </Accordion.Title>
    )}
    <Accordion.Content active={isActive} className={styles.row}>
      <Accordion styled style={{ width: '100%' }}>
        <FileAccordionItem name="Ligands" FileList={Ligand} />
        <FileAccordionItem name="Receptors" FileList={Receptor} />
        <FileAccordionItem name="Configs" FileList={Config} />
      </Accordion>
    </Accordion.Content>
  </>
);
