

export const PublicRoutes = {
  LOGIN_PAGE: "/login",
  VERIFY_EMAIL_PAGE: "/verification/:email/:code",
  RESET_PASSWORD_PAGE: "/reset-password/:email/:code",
  NOT_FOUND_PAGE: "/404",
} as const;

export const PrivateRoutes = {
  HOME_PAGE: "/",
  USER_LIST_PAGE: "/users",
  USER_CREATE_PAGE: "/users/create",
  USER_UPDATE_PAGE: "/users/edit/:id",
  PROJECT_CREATE_PAGE: "/projects/create",
  PROJECT_UPDATE_PAGE: "/projects/:id",
  PROJECT_LIST_PAGE: "/projects",
  RESULT_CREATE_PAGE: "/results/:combinationId/create",
  RESULT_LIST_PAGE: "/results",
  LIGAND_LIST_PAGE: "/ligands",
  LIGAND_CREATE_PAGE: "/ligands/create",
  RECEPTOR_LIST_PAGE: "/receptors",
  RECEPTOR_CREATE_PAGE: "/receptors/create",
  CONFIG_LIST_PAGE: "/dockingconfigs",
  CONFIG_CREATE_PAGE: "/dockingconfigs/create"
} as const;

export const Routes = {
  ...PublicRoutes,
  ...PrivateRoutes
} as const;
