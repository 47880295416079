import React from 'react';
import i18n from 'i18next';

class Result extends React.Component {
    render() {

        if (this.props.match.params.isSuccess === 'true') {
            return (
                <div className="ui placeholder segment">
                    <div className="ui icon header">
                        <i className="icon check blue"></i>
                        {i18n.t('Done')}
                    </div>
                    {/* <a className="ui primary button" href={process.env.REACT_APP_GAME_ADDRESS} >
                        {i18n.t('GoToGame')}
                    </a> */}
                </div>
            );
        }
        return (
            <div className="ui placeholder segment">
                <div className="ui icon header">
                    <i className="icon x icon red"></i>
                    {i18n.t('Fail')}
                </div>
                {/* <a className="ui primary button" href={process.env.REACT_APP_GAME_ADDRESS} >
                    {i18n.t('GoToGame')}
                </a> */}
            </div>
        );
    }
}

export { Result }
