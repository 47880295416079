import _ from "lodash";
import { types } from "../_actions";

const ResultReducer = (state = {}, action) => {
  switch (action.type) {
    case types.RESULT_FETCH_ALL:
      return { ...state, ..._.mapKeys(action.payload, "fileId") };

    default:
      return state;
  }
};

export default ResultReducer;
