import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { UserForm } from './UserForm';

class UserCreate extends React.Component {

    onSubmit = formValues => {
        this.props.createUser(formValues);
    }

    render() {
        return (
            <div>
                <UserForm
                    onSubmit={this.onSubmit}
                    title="CreateUser" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const actionCreator = {
    createUser: userActions.create
}

const connectedUserCreate = connect(mapStateToProps, actionCreator)(UserCreate);

export { connectedUserCreate as UserCreate };
