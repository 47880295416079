import i18n from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { IconicInput, required } from '../_shared';
import { userActions } from '../../_actions';

class ResetPassword extends React.Component {

    onSubmit = (formValues) => {
        this.props.resetPassword(
            this.props.match.params.email,
            this.props.match.params.code,
            formValues.password
        );
    }

    render() {
        return (
            <div className="ui middle aligned center aligned grid">
                <div className="column">
                    <p className="ui image header">
                        {i18n.t('ChangePassDescription')}
                    </p>
                    <form
                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                        className="ui large form error"
                    >
                        <div className="ui stacked segment">

                            <Field
                                component={IconicInput}
                                name="password"
                                type="password"
                                placeholder="newPassword"
                                icon="lock"
                                validate={[required]}
                            />

                            <button className="ui fluid large blue submit button">
                                {i18n.t('ChangePassword')}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const actionCreators = {
    resetPassword: userActions.resetPassword
}

const connectedComponent = connect(
    mapStateToProps,
    actionCreators
)(ResetPassword);

const formWrapped = reduxForm({
    form: 'reset_password'
})(connectedComponent);

export { formWrapped as ResetPassword }
