import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { authActions } from '_actions';
import { AuthorizationService } from '_services';
import { MenuItem, Menu as SemanticMenu, Icon } from 'semantic-ui-react';
import { Routes } from '_configs';

export function Menu() {
  const dispatch = useDispatch();

  return (
    <SemanticMenu>
      <MenuItem to={Routes.HOME_PAGE} as={Link} >{i18n.t('BOBA')}</MenuItem>
      <MenuItem to={Routes.LIGAND_LIST_PAGE} as={Link} >{i18n.t('Ligand')}</MenuItem>
      <MenuItem to={Routes.RECEPTOR_LIST_PAGE} as={Link} >{i18n.t('Receptor')}</MenuItem>
      <MenuItem to={Routes.CONFIG_LIST_PAGE} as={Link} >{i18n.t('Config')}</MenuItem>
      <MenuItem to={Routes.RESULT_LIST_PAGE} as={Link} >{i18n.t('Result')}</MenuItem>
      <MenuItem to={Routes.PROJECT_LIST_PAGE} as={Link} >{i18n.t('Project')}</MenuItem>
      <MenuItem position="right">
        <Icon name="user" outline size="small" />
        {AuthorizationService.getCurrentUser()?.email}
      </MenuItem>
      <MenuItem onClick={() => authActions.logout()(dispatch)} as={Link}>
        {i18n.t('Logout')}
      </MenuItem>
    </SemanticMenu>
  );
};
