import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

interface Props {
  deleteMode?: boolean;
  onDeleteClick: React.MouseEventHandler<HTMLElement>;
  to: string;
  user?: { email: string };
}

export const ActionPanel = ({ deleteMode, onDeleteClick, to, user }: Props) => (
  <>
    <div className="field btn-holder">
      <Link to={to} className="ui button left floated">{i18n.t('Back')}</Link>
      <button className="ui button primary right floated">{i18n.t('Submit')}</button>
    </div>
    {deleteMode && (
      <div className="ui styled fluid ">
        <br></br>
        <br></br>
        <div className="active content">
          <div className="ui button negative" onClick={onDeleteClick}>{i18n.t('Delete')} {user?.email}</div>
        </div>
      </div>
    )}
  </>
);
