import './LoginPage.css';

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { authActions } from '../../_actions';
import { IconicInput, required } from '../_shared';

class LoginPage extends React.Component {

  onSubmit = (formValues) => {
    this.props.login(formValues.email, formValues.password);
  }

  render() {
    return (
      <div className="ui middle aligned center aligned grid">
        <div className="column">
          <h2 className="ui image header">
            <div className="content">
              Log-in to your account
            </div>
          </h2>
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            className="ui large form error"
          >
            <div className="ui stacked segment">

              <Field
                component={IconicInput}
                name="email"
                type="text"
                placeholder="Email"
                icon="user"
                validate={[required]}
              />

              <Field
                component={IconicInput}
                name="password"
                type="password"
                placeholder="Password"
                icon="lock"
                validate={[required]}
              />

              <button className="ui fluid large blue submit button">Login</button>
            </div>

          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}

const actionCreators = {
  login: authActions.login
}

const connectedLoginPage = connect(mapStateToProps, actionCreators)(LoginPage);

const formWrapped = reduxForm({
  form: 'login_form'
})(connectedLoginPage);

export { formWrapped as LoginPage };
