import { Formik, FormikProps } from "formik";
import { Form, Input } from "formik-semantic-ui-react";
import React, { useEffect } from "react";
import i18n from "i18next";
import { Button, Header } from "semantic-ui-react";
import { useFiles, useProject, useRequest, useSelectable } from "hooks";
import { EndPoints } from "_configs";
import { ApiProject } from "models";
import { object, string, number, array } from 'yup';
import { useHistory, useParams } from "react-router-dom";
import { ProjectCellTemplate } from "../List/ProjectCell";
import { FileListTemplate } from "components/_shared/File";

const initialValues: Omit<ApiProject, 'id'> = {
  name: '',
  minResultCount: 3,
  ligandIds: [],
  receptorIds: [],
  configurationIds: [],
};

const projectSchema = object({
  name: string().required(),
  minResultCount: number().required().positive().integer(),
  ligandIds: array().required().min(1),
  receptorIds: array().required().min(1),
  configurationIds: array().required().min(1),
});

const FormikForm = ({ setFieldValue, isSubmitting, values }: FormikProps<Omit<ApiProject, "id">>) => {
  const { data: ligands } = useFiles("Ligand");
  const { data: configs } = useFiles("Config");
  const { data: receptors } = useFiles("Receptor");

  const ligandProps = useSelectable(ligands, (selected) => setFieldValue('ligandIds', selected), values.ligandIds);
  const receptorProps = useSelectable(receptors, (selected) => setFieldValue('receptorIds', selected), values.receptorIds);
  const configProps = useSelectable(configs, (selected) => setFieldValue('configurationIds', selected), values.configurationIds);

  return (
    <Form>
      <Header as="h1">{i18n.t(`Project`)}</Header>
      <Input name="name" label={i18n.t("Name")} />
      <Input name="minResultCount" type="number" label={i18n.t("MinResultCount")} />
      <Header as="h4">{i18n.t("Files")}</Header>
      <ProjectCellTemplate
        Ligand={<FileListTemplate files={ligands} {...ligandProps} />}
        Receptor={<FileListTemplate files={receptors} {...receptorProps} />}
        Config={<FileListTemplate files={configs} {...configProps} />}
      />
      <Button type="submit" loading={isSubmitting}>{i18n.t("Submit")}</Button>
    </Form>
  );
}

interface Props {
  project?: ApiProject
}

export const UpsertProject = ({ project }: Props) => {
  const { response, submit } = useRequest<typeof initialValues, ApiProject>(
    project ? 'put' : 'post',
    project ? EndPoints.PROJECT_UPDATE(project.id) : EndPoints.PROJECT_CREATE
  );

  const history = useHistory();

  useEffect(() => {
    if (!response) return;
    history.goBack();
  }, [response, history]);

  return (
    <Formik
      initialValues={project || initialValues}
      onSubmit={(values) => submit(values)}
      validationSchema={projectSchema}
    >{FormikForm}
    </Formik>
  );
};

export const UpdateProject = () => {
  let { id } = useParams<{ id?: string }>();
  const { data: project, error } = useProject(id);

  console.log({ project, id, error })
  if (!project) return null;

  return <UpsertProject {...{ project }} />;
}
