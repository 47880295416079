import { useMemoObject, useRefObject } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

function toRecord(selectedIds: string[]): Record<string, true> {
  return selectedIds.reduce((record, id) => ({ ...record, [id]: true }), {});
}

export function useSelectable<T extends { id: string }>(items?: T[], onChange?: (selected: string[]) => void, initialState?: string[]) {
  const [selectedIds, setSelectedIds] = useState<Record<string, true>>(initialState ? toRecord(initialState) : {});
  const onChangeRef = useRefObject(onChange);
  const isAllToggled = useMemo(() => items?.every(item => selectedIds[item.id]) ?? false, [items, selectedIds]);

  useEffect(() => {
    onChangeRef?.current && onChangeRef.current(Object.keys(selectedIds));
  }, [selectedIds, onChangeRef]);

  const toggleAll = useCallback(() => {
    if (isAllToggled) return setSelectedIds({});
    items?.forEach((item) => setSelectedIds(prev => ({ ...prev, [item.id]: true })));
  }, [items, isAllToggled]);

  const toggleId = useCallback((id: string) => {
    setSelectedIds(prev => {
      const curr = { ...prev };
      if (curr[id]) {
        delete curr[id];
        return curr;
      }

      const found = items?.find(i => i.id === id);
      if (typeof found === 'undefined') return curr;

      curr[id] = true;
      return curr;
    });
  }, [items]);


  return useMemoObject({ selectedIds, toggleId, toggleAll, isAllToggled });
}
