import { useDelete } from "hooks";
import React from "react";
import { useCallback } from "react";
import { useToggle } from "react-use";
import { Button, Confirm, Icon } from "semantic-ui-react";
import i18n from "i18next";
import { mutate } from "swr";

interface Props {
  endpoint: string;
  mutateEndpoint?: string;
}

export const ArchiveIdsButton = ({ endpoint, mutateEndpoint = '' }: Props) => {
  const [deleteClicked, toggleDeleteClicked] = useToggle(false);
  const { submit, pending: deletePending } = useDelete<any>(endpoint);

  const submitDelete = useCallback(async () => {
    toggleDeleteClicked(false);
    await submit();
    mutateEndpoint.length && mutate(mutateEndpoint);
  }, [submit, mutateEndpoint, toggleDeleteClicked]);

  return (
    <>
      <Button icon color="red" disabled={deletePending} onClick={() => toggleDeleteClicked(true)} type="button">
        <Icon name="delete" />
        {i18n.t("Delete")}
      </Button>
      <Confirm
        open={deleteClicked}
        onCancel={() => toggleDeleteClicked(false)}
        onConfirm={() => submitDelete()}
      />
    </>
  );
};
