import { toast } from 'react-toastify';
import i18n from 'i18next';

class Notice {
  error(message) {
    toast.error(
      i18n.t(message),
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  info(message) {
    toast.info(
      i18n.t(message),
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
}

export const notice = new Notice();