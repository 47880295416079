import { ApiFileType } from "models";
import { tagRecordToQuery, arrayToQuery } from "_helpers";

export const getHttpConfig = () => ({
  BASE_URL: `${window.process.env.REACT_APP_API_URL}/api/v1`,
});

export const getEndpoint = (fileType: ApiFileType) => {
  switch (fileType) {
    case "Config": return EndPoints.DOCKING_CONFIG_GET_ALL;
    case "Ligand": return EndPoints.LIGAND_GET_ALL;
    case "Receptor": return EndPoints.RECEPTOR_GET_ALL;
    default: return EndPoints.RECEPTOR_GET_ALL;
  };
};

export const EndPoints = {
  LOGIN: "/auth/sign-in",
  VERITY: "/auth/verify",
  UPDATE_PASSWORD: "/auth/changePassword",

  USER_GET_ALL: "/users",
  USER_GET: "/users",
  USER_CREATE: "/users",
  USER_UPDATE: "/users",
  USER_SEARCH: "/users/search?query={query}",
  USER_DELETE: "/users/{query}",

  GAME_GET: "/games",
  GAME_GET_ALL: "/games",
  GAME_CREATE: "/games",
  GAME_NEXT: "/games/{id}/next",
  GAME_STOP: "/games/{id}/stop",

  LIGAND_GET_ALL: (tags: string[]) => `/ligands?${arrayToQuery(tags)}`,
  RECEPTOR_GET_ALL: (tags: string[]) => `/receptors?${arrayToQuery(tags)}`,
  DOCKING_CONFIG_GET_ALL: (tags: string[]) => `/dockingConfigs?${arrayToQuery(tags)}`,
  TAG_GET_ALL: "/tags",

  DOCKING_POST_RESULT: (machineId: string, combinationId: string) => `/dockings/machine/${machineId}/combination/${combinationId}`,
  DOCKING_GET_RESULT_FILE: (combinationId: string) => `/dockings/${combinationId}/results`,

  REPORT: "/report/{id}",

  FILE_DELETE: (fileId: string) => `/Files/${fileId}`,
  FILES_DELETE: (fileIds: string[]) => `/Files?${arrayToQuery(fileIds, 'Ids')}`,
  FILE_DOWNLOAD: (fileId: string) => `/Files/${fileId}`,

  ADMIN_POST_FILE: (fileType: ApiFileType) => `/Files/file/type/${fileType}`,
  ADMIN_POST_FILE_BULK: (fileType: ApiFileType) => `/Files/file/type/${fileType}/bulk`,

  PROJECT_GET_ALL: '/projects',
  PROJECT_CREATE: '/projects',
  PROJECT_GET_RESULTS_FILE: (projectId: string) => `/projects/${projectId}/results/file`,
  PROJECT_UPDATE: (projectId: string) => `/projects/${projectId}`,
  PROJECT_GET: (projectId: string) => `/projects/${projectId}`,
  PROJECT_DELETE: (projectId: string) => `/projects/${projectId}`,
  PROJECTS_DELETE: (projectIds: string[]) => `/projects?${arrayToQuery(projectIds, 'Ids')}`,
  PROJECT_GET_RESULTS: '/projects/results',
  PROJECT_GET_RESULT_COMBINATIONS: (projectId: string) => `/projects/${projectId}/results`,
  PROJECT_GET_FILES: (projectId: string, tags: Record<ApiFileType, string[]>) => `/projects/${projectId}/files?${tagRecordToQuery(tags)}`,
} as const;
