import './declarations';
import 'semantic-ui-css/semantic.min.css';
import 'style/main.sass';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { SWRConfig } from 'swr'

import { store } from './_helpers';
import { App } from './components';
import i18n from './_i18n';

if (!window.process) {
  window.process = {
    env: {
      REACT_APP_API_URL: 'http://localhost:5000'
    }
  };
}

ReactDOM.render(
  <SWRConfig value={{ errorRetryInterval: 30000 }}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </SWRConfig>
  ,
  document.getElementById('root')
);
