import React from 'react';
import { connect } from 'react-redux';
import { UserForm } from './UserForm';
import { userActions } from '../../_actions';
import i18n from 'i18next';
import swal from 'sweetalert'

class UserEdit extends React.Component {

    componentDidMount() {
        this.props.get(this.props.match.params.id);
    }

    onSubmit = formValues => {
        this.props.edit(formValues);
    }

    onUserDelete = formValues => {
        swal({
            title: i18n.t('DangerZone'),
            text: i18n.t('DeleteWarning'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.delete(this.props.match.params.id);
            } else {

            }
        });
    }

    render() {
        return (
            <div>
                <UserForm
                    initialValues={this.props.user}
                    onSubmit={this.onSubmit}
                    onDelete={this.onUserDelete}
                    title="EditUser"
                    editMode="true"
                    deleteMode="true"
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return { user: state.users[ownProps.match.params.id] };
}

const actionCreator = {
    get: userActions.get,
    edit: userActions.edit,
    delete: userActions.delete,
}

const connectedComponent = connect(mapStateToProps, actionCreator)(UserEdit);

export { connectedComponent as UserEdit };
