import React, { useState } from "react";
import { Accordion, Button, Checkbox, Confirm, Icon, Table } from "semantic-ui-react";
import i18n from "i18next";
import styles from './ProjectsList.module.sass';
import { Link } from "react-router-dom";
import { useProjectFiles, useProjects, useSelectable, useTags } from "hooks";
import { ProjectCellTemplate, } from "./ProjectCell";
import { ApiFile, ApiFileType, ApiProject } from "models";
import { useToggle } from "react-use";
import { EndPoints } from "_configs";
import { ArchiveIdsButton } from "components/_shared/ArchiveIdsButton";
import { FileListTemplate } from "components/_shared/File";

interface CellProps {
  project: ApiProject;
  isSelected: boolean;
  toggleIsSelected: () => void;
}

const initialTags = { Ligand: [], Receptor: [], Config: [] };

interface FileListProps {
  sortedFiles?: Record<ApiFileType, ApiFile[]> | undefined;
  name: 'Ligand' | 'Config' | 'Receptor';
  selectedTags: Record<ApiFileType, string[]>;
  setTags: (newTags: Record<ApiFileType, string[]>) => void;
}

function FileList({ name, sortedFiles, selectedTags, setTags }: FileListProps) {
  const files = sortedFiles ? sortedFiles[name] : undefined;
  const { data: tags } = useTags();
  const selectable = useSelectable(files);

  return <FileListTemplate
    {...{ files, tags, ...selectable }}
    setSelectedTags={(newTags: string[]) => setTags({...selectedTags, [name]: newTags })}
  />;

}

function ProjectCell({ project, isSelected, toggleIsSelected }: CellProps) {
  const [isActive, toggleIsActive] = useToggle(false);
  const [selectedTags, setTags] = useState<Record<ApiFileType, string[]>>(initialTags);
  const { data: sortedFiles } = useProjectFiles(isActive ? project.id : undefined, selectedTags);

  return (
    <ProjectCellTemplate
      {...{
        isActive,
        toggleIsActive,
        project,
        Ligand: <FileList name="Ligand" {...{ sortedFiles, selectedTags, setTags }} />,
        Receptor: <FileList name="Receptor" {...{ sortedFiles, selectedTags, setTags }} />,
        Config: <FileList name="Config" {...{ sortedFiles, selectedTags, setTags }} />,
        isSelected,
        toggleIsSelected,
      }}
    />
  )
}

export function ProjectsList() {
  const { data: projects } = useProjects();
  const { toggleId, selectedIds, toggleAll, isAllToggled } = useSelectable(projects);
  const idArr = Object.keys(selectedIds);
  return (
    <Accordion styled style={{ width: '100%' }}>
      <Accordion.Title className={styles.row} style={{ backgroundColor: '#f9fafb', color: 'rgba(0,0,0,.87' }}>
        <div className={styles.cell}></div>
        <div className={styles.cell}></div>
        <div className={styles.cell}></div>
        <div className={styles.cell} style={{ justifySelf: 'right' }} >
          <ArchiveIdsButton endpoint={EndPoints.PROJECTS_DELETE(idArr)} mutateEndpoint={EndPoints.PROJECT_GET_ALL} />
        </div>
      </Accordion.Title>
      <Accordion.Title className={styles.row} style={{ backgroundColor: '#f9fafb', color: 'rgba(0,0,0,.87' }}>
        <div className={styles.cell}>{i18n.t("Name")}</div>
        <div className={styles.cell}>{i18n.t("Id")}</div>
        <div className={styles.cell}>{i18n.t("MinResultCount")}</div>
        <div className={styles.cell}>
          {toggleAll && typeof isAllToggled === 'boolean' && (
            <Checkbox checked={isAllToggled} style={{ float: 'right' }} onClick={() => toggleAll()} />
          )}
        </div>
      </Accordion.Title>
      {projects?.map((project) => (<ProjectCell {...{
        project,
        isSelected: selectedIds[project.id] || false,
        toggleIsSelected: () => toggleId(project.id)
      }} />))}
      <Accordion.Title style={{ backgroundColor: '#f9fafb', color: 'rgba(0,0,0,.87' }}>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Link to={`/projects/create`} className="ui white" >
                <Button icon size="small" primary floated="right">
                  <Icon name="plus" />
                  {i18n.t(`CreateProject`)}
                </Button>
              </Link>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Accordion.Title>
    </Accordion>
  );
};
