import React from 'react';
import i18n from 'i18next';

class Header extends React.Component {
  render() {
    return (
      <h3 className="ui dividing header">
        {i18n.t(this.props.title)}
      </h3>
    );
  }
}

export { Header };
