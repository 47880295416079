import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { Formik } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import { Button, Icon, Input, Checkbox } from "semantic-ui-react";
import { ApiFileType } from "models";
import styles from './Create.module.sass';
import { usePost } from "hooks";
import { useList, useToggle } from 'react-use';
import { EndPoints } from "_configs";
import { useHistory } from "react-router-dom";
import { ListActions } from "react-use/lib/useList";

interface Props {
  fileType: ApiFileType
}

export const FileCreate = ({ fileType }: Props) => {
  const [file, setFile] = useState<File>();
  const [tags, tagActions] = useList<string>(['']);
  const [isBulk, toggleBulk] = useToggle(false);
  const singleRes = usePost(EndPoints.ADMIN_POST_FILE(fileType));
  const bulkRes = usePost(EndPoints.ADMIN_POST_FILE_BULK(fileType));

  const { response, submit, pending } = isBulk ? bulkRes : singleRes;

  const history = useHistory();

  useEffect(() => {
    if (!response) return;
    history.goBack();
  }, [response, history]);

  return (
    <FileCreateTemplate
      {...{
        file,
        submit,
        setFile,
        pending,
        toggleBulk,
        isBulk,
        tags,
        tagActions
      }}
    />
  );
};

export interface FileCreateProps {
  file?: File;
  submit: ReturnType<typeof usePost>["submit"];
  setFile: (value: React.SetStateAction<File | undefined>) => void;
  pending?: boolean;
  toggleBulk?: (toggle?: any) => void;
  isBulk?: boolean;
  tags?: string[];
  tagActions?: ListActions<string>;
}

export const FileCreateTemplate = ({ file, submit, setFile, pending, toggleBulk, isBulk, tags, tagActions }: FileCreateProps) => (

  <Formik
    initialValues={{}}
    onSubmit={async () => {
      if (!file) return;
      const formData = new FormData();
      formData.append('file', file);
      const params = tags ? tags.filter(Boolean).map(t => ['tags', t]) : undefined;
      await submit(formData, "params");
    }}
  >
    <Form>
      <label htmlFor="file">{i18n.t("SelectFile")}</label>
      <div className={styles.inputContainer}>
        <Input id="file" name="file" type="file" onChange={(e) => setFile(e.currentTarget.files ? e.currentTarget.files[0] : undefined)} />
      </div>
      {toggleBulk && <div className={styles.inputContainer}>
        <label htmlFor="bulk">{i18n.t("ZipFolder")}</label>
        <br />
        <Checkbox name="bulk" onChange={() => toggleBulk(!isBulk)} />
      </div>}
      {tags && <label htmlFor="tags">{i18n.t("AddTag")}</label>}
      {tags && tagActions && tags.map((_, index) => (
        <div className={styles.tagContainer} key={index} onKeyDown={(e) => e.key === 'Enter' && tagActions.insertAt(tags.length, '')}>
          <Input id={`tag${index}`} name={`tag${index}`} type="text" onChange={(e) => tagActions.updateAt(index, e.currentTarget.value)} />
          <Button type="button" icon circular onClick={() => tagActions.insertAt(tags.length, '')}>
            <Icon name="plus" />
          </Button>
        </div>
      ))}
      <Button type="submit" loading={pending}>{i18n.t("Submit")}</Button>
    </Form>
  </Formik>
)
