import { useCallback, useMemo, useState } from "react";
import { http } from "_services/http.service";
import { notice } from "_helpers";
import i18n from "i18next";

export function useRequest<TBody, TResponse = any>(type: 'post' | 'delete' | 'put', url: string) {
  const [pending, setPending] = useState(false);
  const [response, setResponse] = useState<TResponse>();
  const [error, setError] = useState<string>();
  const submit = useCallback(async (body: TBody | undefined = undefined, params: ConstructorParameters<typeof URLSearchParams>[0] = {}) => {
    setPending(true);
    setError(undefined);
    setResponse(undefined);

    try {
      const searchParams = (new URLSearchParams(params)).toString();
      const res = await http[type](`${url}${searchParams.length > 0 ? `?${searchParams}` : ''}`, body);
      setResponse(res);
      notice.info(i18n.t("Success"));
      return res;
    } catch (err) {
      if (typeof err === 'string') return setError(err);
      setError('Something went wrong');
      notice.error(`Something went wrong: ${err}`);
    } finally {
      setPending(false);
    }
  }, [url, type]);

  return useMemo(() => ({ pending, response, error, submit }), [pending, response, error, submit]);
}

export const usePost = <TBody, TRes>(url: string) => useRequest<TBody, TRes>("post", url);
export const usePut = <TBody, TRes>(url: string) => useRequest<TBody, TRes>("put", url);
export const useDelete = <TRes>(url: string) => useRequest<undefined, TRes>("delete", url);
