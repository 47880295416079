import _ from 'lodash';
import { types } from '../_actions';

const gameReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GAME_FETCH:
        case types.GAME_CREATE:
        case types.GAME_EDIT:
            return { ...state, [action.payload.id]: action.payload };

        case types.GAME_FETCH_ALL:
            return { ...state, ..._.mapKeys(action.payload, 'id') };

        case types.LOGOUT:
        case types.LOGIN_SUCCESS:
        case types.LOGIN_FAILURE:
            return {};

        default:
            return state;
    };
}

export default gameReducer;
