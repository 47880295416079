import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthorizationService } from './authorization.service';
import { getHttpConfig } from '../_configs';
import { notice, history } from '../_helpers';

type BlobRequestConfig = AxiosRequestConfig & { responseType: 'blob' };


export const http = {
  getResponse,
  get,
  post,
  put,
  delete: _delete,
  download
}

const server = axios.create();

async function getResponse(url: string, config: BlobRequestConfig): Promise<AxiosResponse<Blob>>;
async function getResponse<T>(url: string, config: AxiosRequestConfig): Promise<AxiosResponse<T>>;
async function getResponse<T = any>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
  const res = await server.get(url, {
    headers: {
      Authorization: `Bearer ${AuthorizationService.getCurrentUserToken()}`
    },
    baseURL: getHttpConfig().BASE_URL,
    ...config
  });

  if (config.responseType === 'blob') return res as AxiosResponse<Blob>;
  return res as AxiosResponse<T>;
}

async function get<T = any>(url: string, config: AxiosRequestConfig = {}) {
  try {
    const res = await getResponse<T>(url, config);
    return handleResponse(res);
  } catch (e) {
    throw Error(handleError(e as AxiosError<any>));
  }
}

function post<TBody>(url: string, data: TBody) {
  return server.post(
    url,
    data,
    {
      headers: {
        Authorization: `Bearer ${AuthorizationService.getCurrentUserToken()}`
      },
      baseURL: getHttpConfig().BASE_URL
    }).then(handleResponse, handleError);
}

function put<TBody>(url: string, data: TBody) {
  return server.put(
    url,
    data,
    {
      headers: {
        Authorization: `Bearer ${AuthorizationService.getCurrentUserToken()}`
      },
      baseURL: getHttpConfig().BASE_URL
    }).then(handleResponse, handleError);
}

function _delete(url: string) {
  return server.delete(
    url,
    {
      headers: {
        Authorization: `Bearer ${AuthorizationService.getCurrentUserToken()}`
      },
      baseURL: getHttpConfig().BASE_URL
    }).then(handleResponse, handleError);
}

function download(url: string, name: string) {
  server.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${AuthorizationService.getCurrentUserToken()}`
      },
      baseURL: getHttpConfig().BASE_URL
    })
    .then(
      response => {
        const url = window.URL.createObjectURL(new Blob(["\ufeff", response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
}

function handleResponse<T>(response: AxiosResponse<T>): T {
  return response.data;
}

function handleError(err: AxiosError): string {
  if (err.response) {
    switch (err.response.status) {
      case 403:
        AuthorizationService.logout();
        history.push('/');
        break;

      case 500:
      default:
        if (typeof err.response.data.errors === 'object') {
          Object.values(err.response.data.errors).forEach(messages => {
            if (!Array.isArray(messages)) return;
            messages.forEach(message => {
              notice.error(message);
            });
          });
        }
        else if (err.response.data.Message) {
          notice.error(err.response.data.Message);
        }
        else {
          notice.error(err.response.statusText);
        }
        break;
    }
  }
  else {
    notice.error('Something bad happened!');
  }

  throw Error((err && err.message) || err.response?.statusText || '');
}
