import _ from "lodash";
import { types } from "../_actions";

const receptorReducer = (state = {}, action) => {
  switch (action.type) {
    case types.RECEPTOR_FETCH_ALL:
      return { ...state, ..._.mapKeys(action.payload, "id") };

    default:
      return state;
  }
};

export default receptorReducer;
