import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../_actions';

class EmailVerification extends React.Component {

    componentDidMount() {
        this.props.verify(this.props.match.params.email, this.props.match.params.code);
    }

    render() {
        return null;   
    }
}

const mapStateToProps = (state) => {
    return {
        verified: state.auth.isVerified
    };
}

const actionCreator = {
    verify: authActions.verify
}

const connectedComponent = connect(
    mapStateToProps,
    actionCreator
)(EmailVerification);

export { connectedComponent as EmailVerification };
