import { FileCreateTemplate } from "components/_shared/File"
import { usePost } from "hooks";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EndPoints } from "_configs";

export const ResultCreate = () => {
  let { combinationId = "" } = useParams<{ combinationId?: string }>();
  const [file, setFile] = useState<File>();
  const { submit, pending, response } = usePost(EndPoints.DOCKING_POST_RESULT("some-machine", combinationId));
  const history = useHistory();

  useEffect(() => {
    if (typeof response === 'undefined') return;
    history.goBack();
  }, [response, history]);

  return (
    <FileCreateTemplate
      {...{
        file,
        submit,
        setFile,
        pending
      }}
    />
  );
}
