import _ from 'lodash';
import { types } from '../_actions';

const users = (state = {}, action) => {
    switch (action.type) {
        case types.ACCOUNT_FETCH_ALL:
            return { ...state, ..._.mapKeys(action.payload, 'id') };

        case types.ACCOUNT_FETCH:
            return { ...state, [action.payload.id]: action.payload };

        case types.ACCOUNT_CREATE:
            return { ...state, [action.payload.id]: action.payload };

        case types.ACCOUNT_DELETE:
            var a = _.omit(state, action.payload);
            return a;

        case types.ACCOUNT_SEARCH:
            return action.payload;

        default:
            return {};
    }
}

export default users;
