import { http } from './http.service';

import { EndPoints } from '../_configs';

export const userService = {
    get,
    getAll,
    create,
    edit,
    updatePassword,
    search,
    delete: _delete
}

function get(id) {
    return http.get(EndPoints.USER_GET + `/${id}`);
}

function getAll() {
    return http.get(EndPoints.USER_GET_ALL);
}

function search(query) {
    return http.get(EndPoints.USER_SEARCH.replace('{query}', query));
}

function create(firstName, lastName, email, password, role) {
    return http.post(
        EndPoints.USER_CREATE,
        { firstName, lastName, email, password, userName: email, role }
    );
}

function edit(id, firstName, lastName, email, password, role, state) {
    return http.put(
        EndPoints.USER_UPDATE + `/${id}`,
        { firstName, lastName, email, password, role, state }
    );
}

function updatePassword(email, code, password) {
    return http.post(EndPoints.UPDATE_PASSWORD, { email, code, password });
}


function _delete(id) {
    return http.delete(EndPoints.USER_DELETE.replace('{query}', id));
}
