import { userService } from '../_services';
import { types } from './types';
import { toAction, history } from '../_helpers';

export const userActions = {
    get,
    getAll,
    create,
    edit,
    search,
    resetPassword,
    delete: _delete
}

function get(id) {
    return async dispatch => {
        const user = await userService.get(id);
        dispatch(toAction(types.ACCOUNT_FETCH, user));
    }
}

function getAll() {
    return async dispatch => {
        const users = await userService.getAll();
        dispatch(toAction(types.ACCOUNT_FETCH_ALL, users));
    };
}

function create({ firstName, lastName, email, password, role }) {

    return async dispatch => {
        const user = await userService.create(firstName, lastName, email, password, parseInt(role));
        dispatch(toAction(types.ACCOUNT_CREATE, user));
        history.push('/users');
    };
}

function edit({ id, firstName, lastName, email, password, role, state }) {
    return async dispatch => {
        const user = await userService.edit(id, firstName, lastName, email, password, parseInt(role), parseInt(state));
        dispatch(toAction(types.ACCOUNT_EDIT, user));
        history.push('/users');
    };
}

function _delete(id) {
    return async dispatch => {
        await userService.delete(id);
        dispatch(toAction(types.ACCOUNT_DELETE, id));
        history.push('/users');
    };
}

function search(query) {
    return async dispatch => {
        const users = await userService.search(query);
        dispatch(toAction(types.ACCOUNT_SEARCH, users));
    };
}

function resetPassword(email, code, password) {
    return dispatch => {
        userService.updatePassword(email, code, password)
            .then(
                response => {
                    dispatch(toAction(types.UPDATE_PASSWORD, response.isSuccessful));
                    history.push(`/result/${response.isSuccessful}`);
                },
                err => {
                    console.log('Verification Response #Error', err);
                }
            );
    }
}
