export const types = {
    LOADING: 'LOADING',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGOUT: 'LOGOUT',

    VERIFY: 'VERIFY',
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',

    LIGAND_FETCH_ALL: 'FETCH_LIGAND_S',

    RESULT_FETCH_ALL: 'FETCH_RESULT_S',

    RECEPTOR_FETCH_ALL: 'FETCH_RECEPTOR_S',

    DOCKING_CONFIG_FETCH_ALL: 'FETCH_DOCKING_CONFIG_S',

    GAME_FETCH_ALL: 'FETCH_GAMES',
    GAME_FETCH: 'FETCH_GAME',
    GAME_CREATE: 'CREATE_GAME',
    GAME_DELETE: 'DELETE_GAME',
    GAME_EDIT: 'DELETE_GAME',
    GAME_RELOAD: 'NEXT_RELOAD',
    GAME_SEARCH: 'SEARCH_GAME',
    GAME_SORT: 'SORT_GAME',

    ACCOUNT_FETCH_ALL: 'FETCH_ACCOUNTS',
    ACCOUNT_FETCH: 'FETCH_ACCOUNT',
    ACCOUNT_CREATE: 'CREATE_ACCOUNT',
    ACCOUNT_EDIT: 'EDIT_ACCOUNT',
    ACCOUNT_DISABLE: 'DISABLE_ACCOUNT',
    ACCOUNT_SEARCH: 'SEARCH_ACCOUNT',
    ACCOUNT_DELETE: 'DELETE_ACCOUNT',

};
