import _ from 'lodash';
import { types } from '../_actions/types';

const auth = (state = {}, action) => {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            return { currentUser: action.payload };

        case types.LOGIN_FAILURE:
            return _.omit(state, 'currentUser');

        case types.LOGOUT:
            return _.omit(state, 'currentUser');

        case types.VERIFY:
            return { ...state, isVerified: action.payload };

        default:
            return state;
    };
}

export default auth;
