import React from 'react';

export const IconicInput = ({ input, placeholder, type, icon, meta }) => {

    const hasError = meta.error && meta.touched;
    const iconClass = `icon ${icon}`;
    const className = `field ${hasError ? 'error' : ''}`;

    return (
        <div className={className}>
            <div className="ui left icon input">
                <i className={iconClass}></i>
                <input {...input}
                    placeholder={placeholder}
                    type={type}
                    autoComplete="off"
                />
            </div>
        </div>
    );
};
