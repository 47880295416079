import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { Button, Checkbox, Icon, Select, Table } from 'semantic-ui-react';
import { ApiFile, ApiFileType } from "models";
import { useLocation } from "react-use";
import { useFiles, useSelectable, useTags } from "hooks";
import styles from './FileList.module.sass';
import { EndPoints, getEndpoint } from "_configs";
import { ArchiveIdsButton } from "components/_shared/ArchiveIdsButton";

interface Props {
  fileType: ApiFileType;
}

export const FileList = ({ fileType }: Props) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { pathname = '/' } = useLocation();
  const { data: files } = useFiles(fileType, selectedTags);
  const { data: tags } = useTags();
  const { toggleId, selectedIds, toggleAll, isAllToggled } = useSelectable(files);
  const archiveButtonProps = {
    endpoint: EndPoints.FILES_DELETE(Object.keys(selectedIds)), mutateEndpoint: getEndpoint(fileType)(selectedTags)
  };

  return <FileListTemplate {...{ files, fileType, tags, pathname, setSelectedTags, archiveButtonProps, toggleId, selectedIds, toggleAll, isAllToggled }} />;
}

export interface FileListTemplateProps {
  files?: ApiFile[];
  fileType?: ApiFileType;
  pathname?: string;
  tags?: string[];
  setSelectedTags?: (value: string[]) => void;
  toggleId?: (id: string) => void;
  toggleAll?: () => void;
  isAllToggled?: boolean;
  selectedIds?: Record<string, true>;
  archiveButtonProps?: Parameters<typeof ArchiveIdsButton>[0];
}

export const FileListTemplate = ({
  files,
  fileType,
  pathname,
  tags,
  setSelectedTags,
  toggleId,
  toggleAll,
  isAllToggled,
  selectedIds,
  archiveButtonProps
}: FileListTemplateProps) => {
  const isToggleable = !!toggleId && !!selectedIds;

  return (
    <Table className={styles.table}>
      <Table.Header>
        {tags && setSelectedTags &&
          <Table.Row>
            <Table.HeaderCell colSpan="1">
              {/* <Input icon="search" placeholder={i18n.t("Search")} /> */}
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2">
              <Select
                multiple
                placeholder={i18n.t("Filter Tags")}
                options={tags?.map(tag => ({ text: tag, key: tag, value: tag }))}
                onChange={(_, data) => setSelectedTags(data.value as string[] || [])}
              />
            </Table.HeaderCell>
            {isToggleable && archiveButtonProps && (
              <Table.HeaderCell style={{ textAlign: 'right' }}>
                <ArchiveIdsButton { ...archiveButtonProps } />
              </Table.HeaderCell>
            )}
          </Table.Row>
        }
        <Table.Row>
          <Table.HeaderCell data-width="2" className={styles.cell}>{i18n.t("Name")}</Table.HeaderCell>
          <Table.HeaderCell data-width="2" className={styles.cell}>{i18n.t("Tags")}</Table.HeaderCell>
          <Table.HeaderCell data-width="2" className={styles.cell}>{i18n.t("Id")}</Table.HeaderCell>
          {isToggleable && (
            <Table.HeaderCell
              style={{ textAlign: 'right' }}
              position="right"
              data-width="2"
              className={styles.cell}
            >
              {i18n.t("Selected")}
              {toggleAll && typeof isAllToggled === 'boolean' && (
                <Checkbox checked={isAllToggled} style={{ marginLeft: '1ch' }} onClick={() => toggleAll()} />
              )}
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {files?.map((file) => (
          <Table.Row key={file.id}>
            <Table.Cell>{file.name}</Table.Cell>
            <Table.Cell>{file.tags.join(', ') || '-'}</Table.Cell>
            <Table.Cell>{file.id}</Table.Cell>
            {isToggleable && (
              <Table.Cell style={{ textAlign: 'right' }}>
                <Checkbox checked={selectedIds[file.id] || false} onClick={() => toggleId(file.id)} />
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
      {(fileType && pathname) && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={isToggleable ? '4' : '3'}>
              <Link to={`${pathname}/create`} className="ui white" >
                <Button icon size="small" primary floated="right">
                  <Icon name="plus" />
                  {i18n.t(`New${fileType}`)}
                </Button>
              </Link>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};
